/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";



ion-label.required::after {
  content:' *';
  color:red;
  vertical-align: super;
}
.validation-msg {
  display:none;
}
.ion-invalid .validation-msg {
  display: block;
}

.color-primary {
  color: var(--ion-color-primary);
}
.navbar-top {
  background: #fff;
  width: 100%;
  height: 60px;
  padding: 6px 18px;
  border-bottom: 2px solid #232323;
  top: 0px;
  right: 0;
  left: 0;
  text-align: center;
  position: fixed;
  z-index: 99;
}
ion-toolbar, ion-title {
  height: 60px
}
body{
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  color: #000;
  
}
ion-item ion-button {
  width: 100%;
  margin-top: 30px;
}
ion-content {
  --padding-top: 60px !important;
  --background:var(--dibc-bg-gradient)
}
.plt-iphone ion-content {
  // --padding-top: 110px !important
}
.plt-iphone ion-toolbar {
  // --padding-top: 10px !important
}
ion-menu {
  z-index: 11;
}
ion-modal ion-content, ion-menu ion-content {
  --padding-top: 0 !important;
}
.content-scroll{
  height: calc(100vh - 115px);
  overflow: auto;
  padding: 0 16px;
}

.color-blue {
  color: #0888c0!important;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 3px;
  padding: 5px 16px;
  background: #eee;
  color: #0888c0;
}
table.summary {
  width: 100%;
  text-align: left;
  td:first-child {
      text-align: right;
      padding-right: 10px;
  }
  .total{
    color: (--ion-color-primary);
    font-weight: bold;
  }
}

ion-app{
  margin-top: env(safe-area-inset-top);
  margin-bottom: env(safe-area-inset-bottom)
  }